// 新闻详情
<template>
  <div class="news-details-wrapper">
    <div class="bg-img">
      <img :src="news.poster" alt="" />
    </div>
    <div class="news-details">
      <div class="breadcrumb-wrapper wrap-v2">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/news' }"
            >动态资讯</el-breadcrumb-item
          >
          <el-breadcrumb-item class="now">活动详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="news-info wrap-v2">
        <div class="title">
          {{ news.title }}
          <span>|</span>
          {{ news.subtitle }}
        </div>
        <div class="info">
          <span class="time">时间:{{ news.addTime }}</span>
          <!-- <span class="read">浏览量：{{ news.priority }}</span> -->
        </div>
        <div class="wrap-v1 content" v-html="news.content"></div>
        <div class="key-words">关键词：{{ getKeyWords(news.keywords) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsDetails } from "@/service/allapi.js";
export default {
  data() {
    return {
      news: {}
    };
  },
  created() {
    let id = this.$route.params.id;
    getNewsDetails(id).then(res => {
      this.news = res.data;
    });
  },
  mounted() {
    let page = document.querySelector("#app");
    if (page.scrollTop > 0) {
      page.scrollTop = 0;
    }
  },
  methods: {
    getKeyWords(k) {
      return JSON.parse(k).join("，");
    }
  }
};
</script>
<style scoped lang="scss">
.news-details {
  padding: 50px 0 70px;
  .breadcrumb-wrapper {
    margin-bottom: 70px;
  }
  .news-info {
    text-align: left;
    .title {
      font-size: 42px;
      color: #333333;
      line-height: 59px;
      margin-bottom: 15px;
    }
    .info {
      font-size: 18px;
      color: #9c9c9c;
      line-height: 25px;
      margin-bottom: 60px;
      .time {
        margin-right: 30px;
      }
    }
    .key-words {
      margin-top: 60px;
      font-size: 26px;
      font-weight: 700;
    }
  }
}
/deep/.content {
  .ql-align-center {
    text-align: center;
  }
  font-size: 20px;
  color: #333333;
  line-height: 34px;
  text-align: left;
}
/deep/.now {
  .el-breadcrumb__inner {
    cursor: default;
    color: #007bb9;
    &:hover {
      color: #007bb9;
    }
  }
}
</style>
